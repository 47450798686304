import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppLayout } from "../components/layouts/AppLayout";
import { LandingLayout } from "../components/layouts/LandingLayout";
import { SharedItemLayout } from "../pages/public/sharing/SharedItemLayout";
import { Loading } from "../components/platform/Loading";
import { NavBar } from "../components/layouts/navigation/NavBar";
import { Footer } from "../components/layouts/Footer";

const Dashboard = lazy(() => import("../pages/application/Dashboard"));
const Home = lazy(() => import("../pages/public/Home"));
const Error = lazy(() => import("../pages/public/Error"));
const ContentDeliverers = lazy(
  () => import("../pages/public/ContentDeliverers"),
);
const Login = lazy(() => import("../pages/public/Login"));
const Logout = lazy(() => import("../pages/public/Logout"));
const NotFound = lazy(() => import("../pages/public/NotFound"));
const ProfileEditor = lazy(() => import("../pages/application/ProfileEditor"));
const Contact = lazy(() => import("../pages/public/Contact"));
const Affiliate = lazy(() => import("../pages/public/Affiliate"));
const Faq = lazy(() => import("../pages/public/Faq"));
const AssessmentEditor = lazy(
  () => import("../pages/application/AssessmentEditor"),
);
const Pricing = lazy(() => import("../pages/public/Pricing"));
const SyllabusEditor = lazy(
  () => import("../pages/application/SyllabusEditor"),
);
const Status = lazy(() => import("../pages/public/Status"));
const Assessments = lazy(() => import("../pages/application/Assessments"));
const Comments = lazy(() => import("../pages/application/Comments"));
const CommentEditor = lazy(() => import("../pages/application/CommentEditor"));
const Studio = lazy(() => import("../pages/application/Studio"));
const OutcomesExplorer = lazy(
  () => import("../pages/application/OutcomesExplorer"),
);
const SharingAndPrivacy = lazy(
  () => import("../pages/application/SharingAndPrivacy"),
);
const Uploads = lazy(() => import("../pages/application/Uploads"));
const UploadEditor = lazy(() => import("../pages/application/UploadEditor"));
const LessonEditor = lazy(() => import("../pages/application/LessonEditor"));
const Lessons = lazy(() => import("../pages/application/Lessons"));
const UnitEditor = lazy(() => import("../pages/application/UnitEditor"));
const Units = lazy(() => import("../pages/application/Units"));
const Roadmap = lazy(() => import("../pages/public/Roadmap"));
const Privacy = lazy(() => import("../pages/public/Privacy"));
const Terms = lazy(() => import("../pages/public/Terms"));
const SyllabyteAiLanding = lazy(
  () => import("../pages/public/SyllabyteAiLanding"),
);
const LessonPlayer = lazy(() => import("../pages/public/LessonPlayer"));

export const AppRoutes = (): JSX.Element => {
  return (
    <Suspense
      fallback={
        <>
          <NavBar navItems={[]} />
          <div className="w-full h-screen flex justify-center items-center bg-transparent">
            <Loading size="md" />
          </div>
          <Footer />
        </>
      }
    >
      <Routes>
        {/* Public routes */}
        <Route path="login" element={<Login />} />
        <Route path="error" element={<Error />} />
        <Route path="logout" element={<Logout />} />
        <Route path="status" element={<Status />} />

        <Route path="syllabyteai" element={<SyllabyteAiLanding />} />

        {/* Shared Lesson */}
        <Route path="lesson/:key" element={<LessonPlayer />} />

        {/* Shared item */}
        <Route path="shared/:ownerId/:itemId" element={<SharedItemLayout />} />

        {/* Home Layout */}
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />

          <Route path="content-deliverers" element={<ContentDeliverers />} />

          <Route path="affiliate" element={<Affiliate />} />
          <Route path="contact" element={<Contact />} />
          <Route path="faq" element={<Faq />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="roadmap" element={<Roadmap />} />

          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Route>

        {/* Application */}
        <Route path="/" element={<AppLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profile" element={<ProfileEditor />} />
          <Route path="sharing" element={<SharingAndPrivacy />} />

          {/* Outcomes */}
          <Route path="outcomes" element={<OutcomesExplorer />} />
          <Route path="outcomes/:tab" element={<OutcomesExplorer />} />

          {/* Studio */}
          <Route path="studio" element={<Studio />} />
          <Route path="studio/:tab" element={<Studio />} />

          {/* Uploads */}
          <Route path="uploads" element={<Uploads />} />
          <Route path="uploads/:id" element={<Uploads />} />
          <Route path="uploads/editor/:id" element={<UploadEditor />} />
          <Route path="uploads/editor/:id/:tab" element={<UploadEditor />} />
          <Route
            path="uploads/editor/:id/review/:sliceId"
            element={<UploadEditor />}
          />

          {/* Assessments */}
          <Route path="assessments" element={<Assessments />} />
          <Route path="assessments/:id" element={<Assessments />} />
          <Route path="assessments/editor/:id" element={<AssessmentEditor />} />
          <Route
            path="assessments/editor/:id/:tab"
            element={<AssessmentEditor />}
          />

          {/* Syllabus */}
          <Route
            path="syllabi"
            element={<Navigate to="/outcomes/create" replace />}
          />
          <Route path="syllabi/editor/:id" element={<SyllabusEditor />} />
          <Route path="syllabi/editor/:id/:tab" element={<SyllabusEditor />} />

          {/* Comments */}
          <Route path="comments" element={<Comments />} />
          <Route path="comments/:id" element={<Comments />} />
          <Route path="comments/editor/:id" element={<CommentEditor />} />
          <Route path="comments/editor/:id/:tab" element={<CommentEditor />} />

          {/* Lessons */}
          <Route path="lessons" element={<Lessons />} />
          <Route path="lessons/:id" element={<Lessons />} />
          <Route path="lessons/editor/:id" element={<LessonEditor />} />
          <Route path="lessons/editor/:id/:tab" element={<LessonEditor />} />

          {/* Units */}
          <Route path="units" element={<Units />} />
          <Route path="units/:id" element={<Units />} />
          <Route path="units/editor/:id" element={<UnitEditor />} />
          <Route path="units/editor/:id/:tab" element={<UnitEditor />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
